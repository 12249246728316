<template>
    <div>
        <v-card v-for="(order, index) in orders" :key="index" class="px-7 py-4 mt-7" flat :to="{name: 'account.order', params: {id: order.id}}" :ripple="false">
            <v-card-text class="pa-0">
                <div class="top d-flex flex-row justify-space-between align-end pb-4 mb-5">
                    <div class="">
                        <div class="mb-5 d-flex align-center">
                            <span v-if="!order.status.is_active" class="badge py-2 px-4 shipped mr-5">Shipped</span>
                            <span v-if="order.status.is_active" class="badge py-2 px-4 active mr-5">Active</span>
                            <img src="@/assets/icons/svg/shipping/ltl-dark.svg" width="30" alt="">
                        </div>
                        <div class="text-20-75">
                            #{{ order.id }}
                        </div>
                    </div>
                    <div class="text-right">
                        <router-link :to="{ name: 'account.track', params: {} }" class="mb-1">Track shipping</router-link>
                        <div class="text-14">
                            Ship date: {{dateFormat(order.items[0].date)}}
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="text-16">
                        {{ getCity(order) }} <v-icon>mdi-chevron-right</v-icon> {{ getCity(order, 'to') }}
                    </div>
                    <div class="mt-4">
                        <div v-for="item in filterItems(order)" class="">
                            <div class="text-14-60 mb-1">
                                {{ item.description}}
                            </div>
                            <div class="text-14 mb-5">
                                {{ item.quantity }} Bundles, {{ item.quantity * item.weight }} pounds
                            </div>
                        </div>
                        <div class="">
                            <div class="text-14-60">
                                Carier
                            </div>
                            <div class="">
                                {{ order.is_carrier ? order.carrier.name : 'Freight Crew Inc.' }}
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: ['orders'],
    methods: {
        filterItems(model){
            let items = model.items.filter(function(item){
                return item.is_shipper == 1;
            })
            return items
        },
        getCity(model, type = 'from'){
            let warehouse = model.items.filter(function(item){
                if(type == 'from')
                    return item.is_shipper == 1;
                return item.is_shipper == 0;
            })

            if(warehouse[0])
                return warehouse[0].warehouse.state + ', ' + warehouse[0].warehouse.city;
            return 'not set'
        },
        dateFormat(toFormat = false, dayPlus = 0){
            var obj = new Date();
            if(toFormat)
                obj = new Date(toFormat);
            let month = obj.getMonth() + 1;
            let date = obj.getDate() + dayPlus;
            let year = obj.getFullYear();
            return month + '/' + date + '/' + year;
        },
    }
}
</script>

<style lang="scss">
</style>
