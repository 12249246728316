<template>
    <layout>
        <div class="dashboard mt-5">
            <div class="justify-space-between align-center px-10 d-none d-lg-flex">
                <h1 class="pr-10 heading-2">My orders</h1>
                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" depressed :to="{name: 'account.quote'}">Start new quote</v-btn>
            </div>
            <v-row class="main mt-5 px-md-10">
                <v-col cols="12" lg="7">
                    <card-order :orders="orders" />
                </v-col>
            </v-row>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/AccountLayout.vue'
import CardOrder from '@/components/pages/CardOrder'
export default {
    middleware: 'auth',
    components: {
        Layout,
        CardOrder
    },
    data: () => ({
        orders: [],
        order_load: false,
        quotes: [],
        quote_load: false,

    }),
    mounted(){
        this.getLoads('orders');
    },
    methods: {
        async getLoads(type = 'orders', take = 6, skip = 0){
            var uri = process.env.VUE_APP_API + 'loads/v1/' + type + '?take=' + take + '&skip=' + skip;

            await this.axios.get(uri).then(response => {
                this[type] = Object.assign({}, this[type], response.data);
            }).catch();
        },
        dateFormat(toFormat = false, dayPlus = 0){
            var obj = new Date();
            if(toFormat)
                obj = new Date(toFormat);
            let month = obj.getMonth() + 1;
            let date = obj.getDate() + dayPlus;
            let year = obj.getFullYear();
            return month + '/' + date + '/' + year;
        },
        dateCompare(date1, date2, compare){
            let d1 = new Date(date1)
            let d2 = new Date(date2)

            if(compare == '>'){
                return d1 > d2;
            }
            if(compare == '<'){
                return d1 < d2;
            }
        },
        filterItems(model){
            let items = model.items.filter(function(item){
                return item.is_shipper == 1;
            })
            return items
        },
        getCity(model, type = 'from'){
            let warehouse = model.items.filter(function(item){
                if(type == 'from')
                    return item.is_shipper == 1;
                return item.is_shipper == 0;
            })

            if(warehouse[0])
                return warehouse[0].warehouse.state + ', ' + warehouse[0].warehouse.city;
            return 'not set'
        }

    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.v-card{
    .top{
        border-bottom: 1px dashed #CDE0FF;

    }
    .content{
        .mw-60{
            max-width: 60%;
        }
    }
}
</style>
